import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import "moment/locale/nl";
import classnames from "classnames";
import { regularBarChart, stackedBarChartHours } from "variables/charts";
import NotificationAlert from "react-notification-alert";
import CardsHeader from "components/Headers/CardsHeader";
import firebase from "firebase";
import notify from "../../../../functions/notify.js";
import i18n from "variables/translations/i18n";
import Tour from "components/Support/Tour";
import _ from "lodash";
import { tourSteps } from "variables/tourSteps";
import { DatePicker, Form, InputNumber, Select, Switch, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addNameToHours } from "views/functions/generic.js";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/nl_NL";
import { FIREBASE_HOURS_TABLE } from "constants/FirebaseConstants.js";
import { calculateHoursAndEarnings } from "../../../../functions/dataService";
import { Link } from "react-router-dom";
import urls from "urls.js";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  paginationSize: 5,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {i18n.t("dashboard.uren.rijen1")}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        {i18n.t("dashboard.uren.rijen2")}
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const { Option } = Select;

const AdminUrenregistratie = (props) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const projects = useSelector(
    (state) => state.firebaseReducer.ordered.projects
  );
  const users = useSelector((state) => state.firebaseReducer.data.companyUsers);
  const activeProjects =
    !!projects &&
    _.filter(projects, (project) => {
      return project.value.active;
    });
  const hours = useSelector((state) => state.firebaseReducer.data.companyHours);
  const notifyHoursRef = useRef();
  const hoursChartRef = useRef();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [hoursToEdit, setHoursToEdit] = useState({});
  const [hoursThisYear, setHoursThisYear] = useState({});
  const [projectsForSelect, setProjectsForSelect] = useState([]);
  const [usersForSelect, setUsersForSelect] = useState([]);
  const [hoursForMonthTotal, setHoursForMonthTotal] = useState([]);
  const [hoursForMonthProjects, setHoursForMonthProjects] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [months, setMonths] = useState([]);
  const [editFormModal, setEditFormModal] = useState(false);
  const [activeNavHours, setActiveNavHours] = useState(2);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const currentMonth = moment().format("MMMM");
  const lastYear = moment().year() - 1;
  const [totalHoursLastYear, setTotalHoursLastYear] = useState(0);
  const [currentMonthHours, setCurrentMonthHours] = useState(0);
  const [lastMonthHours, setLastMonthHours] = useState(0);
  const [currentWeekHours, setCurrentWeekHours] = useState(0);
  const [lastWeekHours, setLastWeekHours] = useState(0);
  const [startTour, setStartTour] = useState(false);
  const [invoiceable, setInvoiceable] = useState(false);
  const steps = tourSteps.urenregistratie;

  useEffect(() => {
    if (!!users) {
      let usersForSelect = [];
      _.map(users, (user) => {
        let object = {
          id: user.id,
          text: user.name,
        };
        usersForSelect.push(object);
      });
      setUsersForSelect(usersForSelect);
    }
    if (!!projects) {
      let projectsForSelect = [];
      _.map(projects, (project) => {
        if (project.value.active && project.value.status === "inprogress") {
          let object = {
            id: project.key,
            text: project.value.name,
          };
          projectsForSelect.push(object);
        }
      });
      const sorted = _.sortBy(projectsForSelect, [
        (item) => {
          return item.text.toLowerCase();
        },
      ]);
      setProjectsForSelect(sorted);
    }
  }, [users, projects]);

  /**
   * Get all hours for company (used in admin view)
   * Add name of the issuer to each entry (to show in table)
   * Set array of all hours to state and then calculate all hours and earnings
   * which then gets set in the hours and earnings charts
   */
  useEffect(() => {
    if (!!hours) {
      addNameToHours(hours, users).then((data) => {
        calculateHoursAndEarnings(data, activeProjects, currentYear).then(
          (data) => {
            setTotalHours(data.totalHours);
            setHoursThisYear(data.hoursThisYear);
            setMonths(data.months);
            setHoursForMonthTotal(data.hoursForMonthTotal);
            setHoursForMonthProjects(data.hoursForMonthProjects);
            setCurrentMonthHours(data.totalHoursMonth);
            setCurrentWeekHours(data.totalHoursWeek);
            setLastWeekHours(data.totalHoursLastWeek);
            setLastMonthHours(data.totalHoursLastMonth);
            updateCharts(data);
          }
        );
        calculateHoursAndEarnings(data, activeProjects, lastYear).then(
          (data) => {
            setTotalHoursLastYear(data.totalHours);
          }
        );
      });
    }
  }, [hours]);

  const updateCharts = (data) => {
    if (activeNavHours === 1) {
      let chart = hoursChartRef.chartInstance;
      chart.data = {
        labels: data.months,
        datasets: data.hoursForMonthProjects,
      };
      chart.update();
    }
  };

  const handleSubmitHours = () => {
    form.validateFields().then((object) => {
      object.date = moment(object.date).unix();
      if (!object.userId) object.userId = profile.id;
      if (!object.notes) object.notes = null;
      firebase
        .database()
        .ref(FIREBASE_HOURS_TABLE)
        .push(
          {
            ...object,
            created_at: firebase.database.ServerValue.TIMESTAMP,
            companyId: profile.companyId,
          },
          (error) => {
            if (error) {
              console.error(error);
            } else {
              notify("success", t("meldingen.uren_opgeslagen"), notifyHoursRef);
              form.resetFields(["invoiceable", "hours", "notes", "projectId"]);
            }
          }
        );
    });
  };

  /**
   * Used to toggle between charts 'Per Project' and 'Total'.
   */
  const toggleNavs = (e, value) => {
    e.preventDefault();
    setActiveNavHours(value);
  };

  const handleEditHours = () => {
    editForm.validateFields().then((object) => {
      let objectId = hoursToEdit.id;
      object.date = moment(object.date).unix();
      if (!object.userId) object.userId = profile.id;
      if (!object.notes) object.notes = null;
      setEditFormModal(false);
      firebase
        .database()
        .ref(FIREBASE_HOURS_TABLE + "/" + objectId)
        .update(
          {
            ...object,
            updated_at: firebase.database.ServerValue.TIMESTAMP,
          },
          (error) => {
            if (error) {
              console.error(error);
            } else {
              notify("success", t("meldingen.uren_opgeslagen"), notifyHoursRef);
              editForm.resetFields();
            }
          }
        );
    });
  };

  const onDeleteHour = (id) => {
    firebase
      .database()
      .ref("hours/" + id)
      .remove((error) => {
        if (error) {
          console.error(error);
        } else {
          notify("success", t("meldingen.uren_verwijderd"), notifyHoursRef);
        }
      });
  };

  const openEditHours = (hour) => {
    setInvoiceable(hour.invoiceable);
    setHoursToEdit(hour);
    editForm.setFieldsValue({
      date: moment.unix(hour.date),
      hours: hour.hours,
      projectId: hour.projectId || null,
      notes: hour.notes,
      userId: hour.userId,
      invoiceable: hour.invoiceable,
    });
    setEditFormModal(true);
  };

  const linkActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => openEditHours(row)}>
            {t("dashboard.bewerken")}
          </DropdownItem>
          <DropdownItem onClick={() => onDeleteHour(row.id)}>
            {t("dashboard.verwijderen")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const changeYear = (e, year) => {
    e.preventDefault();

    calculateHoursAndEarnings(hours, projects, year)
      .then((data) => {
        setHoursThisYear(data.hoursThisYear);
        setMonths(data.months);
        setHoursForMonthTotal(data.hoursForMonthTotal);
        setHoursForMonthProjects(data.hoursForMonthProjects);
        setCurrentYear(year);
      })
      .catch((error) => {
        if (error === "no-hours") {
          notify(
            "warning",
            t("meldingen.nohours_year1") + year + t("meldingen.nohours_year2"),
            notifyHoursRef
          );
        }
      });
  };

  const hoursPerProject = {
    labels: months,
    datasets: hoursForMonthProjects,
  };
  const hoursTotal = {
    labels: months,
    datasets: [
      {
        label: t("dashboard.uren.uren"),
        data: hoursForMonthTotal,
        maxBarThickness: 10,
      },
    ],
  };

  return (
    <>
      <Tour
        steps={steps}
        continuous={true}
        run={startTour}
        onFinished={() => setStartTour(false)}
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyHoursRef} />
      </div>
      <CardsHeader
        parentName={t("dashboard.bedrijf")}
        name={t("dashboard.uren.uren")}
        isAdminPage={true}
        data={[
          {
            name: t("dashboard.uren.deze_week"),
            value: Math.round(currentWeekHours * 100) / 100,
            percentageChange:
              lastWeekHours === 0 && currentWeekHours > 0
                ? 100
                : Math.round(
                    ((currentWeekHours - lastWeekHours) / lastWeekHours) *
                      100 *
                      100
                  ) / 100,
          },
          {
            name: currentMonth,
            value: Math.round(currentMonthHours * 100) / 100,
            percentageChange:
              lastMonthHours === 0 && currentMonthHours > 0
                ? 100
                : Math.round(
                    ((currentMonthHours - lastMonthHours) / lastMonthHours) *
                      100 *
                      100
                  ) / 100,
          },
          {
            name: moment().year(),
            value: Math.round(totalHours * 100) / 100,
            percentageChange:
              totalHoursLastYear === 0 && totalHours > 0
                ? 100
                : Math.round(
                    ((totalHours - totalHoursLastYear) / totalHours) * 100 * 100
                  ) / 100,
          },
        ]}
        startTour={() => setStartTour(true)}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={editFormModal}
              toggle={() => setEditFormModal(false)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 mb-3">
                      <span className="card-header-title">
                        {t("dashboard.uren.aanpassen")}
                      </span>
                      <span className="card-header-subtitle">
                        {t("dashboard.vul_formulier_in")}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form form={editForm} layout="vertical">
                      <Form.Item
                        name="date"
                        label={t("dashboard.uren.datum.label")}
                        rules={[
                          {
                            required: true,
                            type: "date",
                            message: t("form.enter_date"),
                          },
                        ]}
                      >
                        <DatePicker
                          locale={locale}
                          format={"DD-MM-YYYY"}
                          size="large"
                          placeholder={t("dashboard.uren.datum.placeholder")}
                          className="w-100"
                        />
                      </Form.Item>
                      <Form.Item
                        name="notes"
                        label={t("dashboard.uren.opmerkingen.label")}
                      >
                        <Input
                          size="large"
                          placeholder={t(
                            "dashboard.uren.opmerkingen.placeholder"
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name="hours"
                        label={t("dashboard.uren.aantal.label")}
                        rules={[
                          {
                            required: true,
                            type: "date",
                            message: t("form.enter_hours"),
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          placeholder={t("dashboard.uren.aantal.placeholder")}
                          className="w-100"
                          min={0.25}
                          max={24}
                          step={0.5}
                        />
                      </Form.Item>
                      <Form.Item
                        name="userId"
                        label={t("dashboard.uren.gebruiker.label")}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={true}
                          size="large"
                          placeholder={t(
                            "dashboard.uren.gebruiker.placeholder"
                          )}
                        >
                          {!!usersForSelect &&
                            usersForSelect.map((user) => (
                              <Option key={user.id} value={user.id}>
                                {user.text}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="projectId"
                        label={t("dashboard.uren.project.label")}
                        rules={[
                          {
                            required: true,
                            message: t("form.enter_project"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={true}
                          size="large"
                          placeholder={t("dashboard.uren.project.placeholder")}
                          notFoundContent={
                            <div>
                              <span>{t("form.no_projects")}</span>{" "}
                              <Link to={urls.projects}>
                                {t("dashboard.navbar.admin.projecten")}
                              </Link>
                            </div>
                          }
                        >
                          {!!projectsForSelect &&
                            projectsForSelect.map((project) => (
                              <Option key={project.id} value={project.id}>
                                {project.text}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="invoiceable"
                        label={t("dashboard.uren.factureerbaar.label")}
                      >
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={invoiceable}
                          onChange={(value) => setInvoiceable(value)}
                        />
                      </Form.Item>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => setEditFormModal(false)}
                        >
                          {i18n.t("dashboard.sluiten")}
                        </Button>
                        <Button
                          className="my-4"
                          color="success"
                          type="button"
                          onClick={() => handleEditHours()}
                        >
                          {i18n.t("dashboard.opslaan")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper" id="urenregistratie-formulier">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">{t("dashboard.uren.toevoegen")}</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                      date: moment(),
                      hours: 0.5,
                      invoiceable: false,
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <Form.Item
                          name="date"
                          label={t("dashboard.uren.datum.label")}
                          rules={[
                            {
                              required: true,
                              type: "date",
                              message: t("form.enter_date"),
                            },
                          ]}
                        >
                          <DatePicker
                            locale={locale}
                            format={"DD-MM-YYYY"}
                            size="large"
                            placeholder={t("dashboard.uren.datum.placeholder")}
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                      <Col md="6">
                        <Form.Item
                          name="notes"
                          label={t("dashboard.uren.opmerkingen.label")}
                        >
                          <Input
                            size="large"
                            placeholder={t(
                              "dashboard.uren.opmerkingen.placeholder"
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Item
                          name="hours"
                          label={t("dashboard.uren.aantal.label")}
                          rules={[
                            {
                              required: true,
                              type: "date",
                              message: t("form.enter_hours"),
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            placeholder={t("dashboard.uren.aantal.placeholder")}
                            className="w-100"
                            min={0.25}
                            max={24}
                            step={0.5}
                          />
                        </Form.Item>
                      </Col>
                      <Col md="6">
                        <Form.Item
                          name="userId"
                          label={t("dashboard.uren.gebruiker.label")}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={true}
                            size="large"
                            placeholder={t(
                              "dashboard.uren.gebruiker.placeholder"
                            )}
                          >
                            {!!usersForSelect &&
                              usersForSelect.map((user) => (
                                <Option key={user.id} value={user.id}>
                                  {user.text}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Item
                          name="projectId"
                          label={t("dashboard.uren.project.label")}
                          rules={[
                            {
                              required: true,
                              message: t("form.enter_project"),
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={true}
                            size="large"
                            placeholder={t(
                              "dashboard.uren.project.placeholder"
                            )}
                            notFoundContent={
                              <div>
                                <span>{t("form.no_projects")}</span>{" "}
                                <Link to={urls.projects}>
                                  {t("dashboard.navbar.admin.projecten")}
                                </Link>
                              </div>
                            }
                          >
                            {!!projectsForSelect &&
                              projectsForSelect.map((project) => (
                                <Option key={project.id} value={project.id}>
                                  {project.text}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md="6">
                        <Form.Item
                          name="invoiceable"
                          label={t("dashboard.uren.factureerbaar.label")}
                        >
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Button
                      color="info"
                      type="button"
                      onClick={() => handleSubmitHours()}
                    >
                      {t("dashboard.toevoegen")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right mb-3">
            <Button
              className="btn-neutral"
              onClick={(e) => changeYear(e, currentYear - 1)}
              size="sm"
            >
              <i className="ni ni-fat-delete align-middle" />
              <span className="align-middle">{currentYear - 1}</span>
            </Button>
            {currentYear !== moment(new Date()).year() && (
              <Button
                className="btn-neutral"
                onClick={(e) => changeYear(e, currentYear + 1)}
                size="sm"
              >
                <span className="align-middle">{currentYear + 1}</span>
                <i className="ni ni-fat-add align-middle" />
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Card id="urenregistratie-tabel">
              <CardHeader>
                <h3 className="mb-0">
                  {t("dashboard.uren.recent")} {currentYear}
                </h3>
                <p className="text-sm mb-0">
                  {t("dashboard.uren.tabel.subtitel_bedrijf")}
                </p>
              </CardHeader>
              <ToolkitProvider
                data={_.orderBy(hoursThisYear, ["date"], ["desc"])}
                keyField="id"
                columns={[
                  {
                    dataField: "date",
                    text: t("dashboard.uren.tabel.datum"),
                    sort: true,
                    formatter: (cell) => {
                      if (cell == null) {
                        return;
                      }
                      return `${moment.unix(cell).format("DD-MM-YYYY")}`;
                    },
                  },
                  {
                    dataField: "hours",
                    text: t("dashboard.uren.tabel.uren"),
                    sort: true,
                  },
                  {
                    dataField: "invoiceable",
                    text: t("dashboard.uren.tabel.factureerbaar"),
                    formatter: (cell) => {
                      if (cell) return <i className="fas fa-check" />;
                      else return <i className="fas fa-times" />;
                    },
                  },
                  {
                    dataField: "projectName",
                    text: t("dashboard.uren.tabel.project"),
                    sort: true,
                  },
                  {
                    dataField: "notes",
                    text: t("dashboard.uren.tabel.opmerkingen"),
                  },
                  {
                    dataField: "name",
                    text: t("dashboard.uren.tabel.wie"),
                  },
                  {
                    dataField: "actions",
                    text: t("dashboard.uren.tabel.acties"),
                    formatter: linkActionButtons,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        {t("dashboard.uren.tabel.zoeken")}
                        <SearchBar
                          className="form-control-sm"
                          placeholder={t(
                            "dashboard.uren.tabel.zoeken_placeholder"
                          )}
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
          <Col lg="6">
            <Card>
              <CardHeader>
                <Row>
                  <div className="col">
                    <h6 className="surtitle">
                      {activeNavHours === 1 && " Per Project, "}{" "}
                      {t("dashboard.uren.per_maand")}
                    </h6>
                    <h5 className="h3 mb-0">
                      {t("dashboard.uren.gewerkt")}
                      {currentYear}
                    </h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          id="grafiek-toggle"
                          className={classnames("py-2 px-3", {
                            active: activeNavHours === 1,
                          })}
                          href="#"
                          onClick={(e) => toggleNavs(e, 1, "activeNavHours")}
                        >
                          <span className="d-none d-md-block">
                            {t("dashboard.per_project")}
                          </span>
                          <span className="d-md-none">PP</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNavHours === 2,
                          })}
                          data-toggle="tab"
                          href="#"
                          onClick={(e) => toggleNavs(e, 2, "activeNavHours")}
                        >
                          <span className="d-none d-md-block">
                            {t("dashboard.totaal")}
                          </span>
                          <span className="d-md-none">T</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="chart">
                  <Bar
                    ref={hoursChartRef}
                    data={activeNavHours === 1 ? hoursPerProject : hoursTotal}
                    options={
                      activeNavHours === 1
                        ? stackedBarChartHours.options
                        : regularBarChart.options
                    }
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminUrenregistratie;
