import NotificationAlert from "react-notification-alert";
import CardsHeader from "../../Headers/CardsHeader";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import GraphCard from "../../Graphs/GraphCard";
import {
  stackedBarChartEarnings,
  stackedBarChartHours,
} from "../../../variables/charts";
import TableCard from "../../Tables/TableCard";
import React, { useState } from "react";
import { tourSteps } from "variables/tourSteps";
import Tour from "components/Support/Tour";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getTurnoverByTableRow } from "../../../views/functions/projects";
import ExcelExport from "../../Export/Excel";

const HoursDashboard = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { companyHoursChartRef, companyEarningsChartRef } = ref;
  const [startTour, setStartTour] = useState(false);
  const steps = tourSteps.urenoverzicht;
  const allHoursForCompany = _.orderBy(
    props.allHoursForCompany,
    ["date"],
    ["desc"],
  );

  const hoursPerProject = {
    labels: !!props.months ? props.months : [],
    datasets: props.hoursForMonthProjects,
  };

  const earningsPerProject = {
    labels: !!props.months ? props.months : [],
    datasets: props.earningsForMonthProjects,
  };

  return (
    <>
      <Tour
        steps={steps}
        continuous={true}
        run={startTour}
        onFinished={() => setStartTour(false)}
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={props.notificationRef} />
      </div>
      <CardsHeader
        parentName={props.breadcrumbParentName}
        name={t("dashboard.uren.uren")}
        isAdminPage={props.isAdminPage}
        startTour={() => setStartTour(true)}
        data={[
          {
            name: t("dashboard.uren.totaal"),
            value: Math.round(props.totalHours * 100) / 100,
          },
          {
            name: t("dashboard.uren.omzet_uren"),
            value: "€" + Math.round(props.earningsTotal * 100) / 100,
          },
        ]}
        hideTourIcon={props.hideTourIcon}
        dataFilters={props.dataFilters}
        dataFilterProject={props.dataFilterProject}
        dataFilterUser={props.dataFilterUser}
        setOnlyInvoicableToggle={props.setOnlyInvoicableToggle}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <ExcelExport
              data={[
                {
                  columns: [
                    {
                      title: t("dashboard.kalender.maand"),
                    },
                    {
                      title: t("dashboard.uren.tabel.datum"),
                    },
                    {
                      title: t("dashboard.uren.tabel.wie"),
                    },
                    {
                      title: t("dashboard.uren.tabel.uren"),
                    },
                    {
                      title: t("dashboard.uren.tabel.factureerbaar"),
                    },
                    {
                      title: t("dashboard.uren.tabel.project"),
                    },
                    {
                      title: t("dashboard.uren.tabel.opmerkingen"),
                    },
                    {
                      title: t("dashboard.uren.tabel.omzet"),
                    },
                  ],
                  data:
                    allHoursForCompany.length > 0
                      ? allHoursForCompany.map((row) => {
                          return [
                            {
                              value: moment.unix(row.date).format("MMMM"),
                            },
                            {
                              value: moment.unix(row.date).format("DD-MM-YYYY"),
                            },
                            {
                              value: row.name,
                            },
                            {
                              value: row.hours,
                            },
                            {
                              value: row.invoiceable
                                ? t("dashboard.ja")
                                : t("dashboard.nee"),
                            },
                            {
                              value: row.projectName,
                            },
                            {
                              value: row.notes,
                            },
                            {
                              value: getTurnoverByTableRow(props.projects, row),
                            },
                          ];
                        })
                      : [],
                },
              ]}
              fileName={
                t("dashboard.uren.uren") +
                "-export-" +
                moment().format("DD-MM-YYYY")
              }
            >
              {t("dashboard.excel_export")}
            </ExcelExport>
          </Col>
        </Row>
        <Row>
          <GraphCard
            cardSize={"6"}
            cardTitle={t("dashboard.uren.per_maand")}
            cardSubTitle={t("dashboard.uren.gewerkt")}
            dataFilterType={props.dataFilterType}
            activeNavProps={props.activeNavHours}
            className={"chart-canvas"}
            ref={companyHoursChartRef}
            data={hoursPerProject}
            options={stackedBarChartHours.options}
          />
          <GraphCard
            cardSize={"6"}
            cardTitle={t("dashboard.uren.per_maand")}
            cardSubTitle={t("dashboard.uren.omzet_uren")}
            dataFilterType={props.dataFilterType}
            activeNavProps={props.activeNavEarnings}
            className={"chart-canvas"}
            ref={companyEarningsChartRef}
            data={earningsPerProject}
            options={stackedBarChartEarnings.options}
          />
        </Row>
        <Row>
          <TableCard
            cardTitle={t("dashboard.uren.recent")}
            cardSubTitle={t("dashboard.uren.tabel.subtitel_bedrijf")}
            cardSize={"12"}
            tableData={allHoursForCompany}
            isLoading={props.isLoading}
            tableColumns={[
              {
                dataField: "date",
                text: t("dashboard.uren.tabel.datum"),
                formatter: (cell) => {
                  return `${moment.unix(cell).format("DD-MM-YYYY")}`;
                },
              },
              {
                dataField: "hours",
                text: t("dashboard.uren.tabel.uren"),
              },
              {
                dataField: "invoiceable",
                text: t("dashboard.uren.tabel.factureerbaar"),
                formatter: (cell) => {
                  if (cell) return <i className="fas fa-check" />;
                  else return <i className="fas fa-times" />;
                },
              },
              {
                dataField: "projectName",
                text: t("dashboard.uren.tabel.project"),
              },
              {
                dataField: "notes",
                text: t("dashboard.uren.tabel.opmerkingen"),
              },
              {
                dataField: "name",
                text: t("dashboard.uren.tabel.wie"),
              },
            ]}
          />
        </Row>
      </Container>
    </>
  );
});

export default HoursDashboard;
